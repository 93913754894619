import AuthorOne from '../../assets/image/crypto/author-4.png';
import AuthorOnea from '../../assets/image/crypto/author-4a.png';
import AuthorOneb from '../../assets/image/crypto/author-4b.png';
import AuthorOnec from '../../assets/image/crypto/author-4c.png';
import AuthorOned from '../../assets/image/crypto/author-4d.png';
import AuthorOnee from '../../assets/image/crypto/author-4e.png';
import AuthorOnef from '../../assets/image/crypto/author-4f.png';
import AuthorOneg from '../../assets/image/crypto/author-4g.png';
import AuthorTwo from '../../assets/image/crypto/author-2.jpg';
import AuthorThree from '../../assets/image/crypto/author-3.jpg';
import AuthorFour from '../../assets/image/crypto/author-1.jpg';
import FEATURE1 from '../../assets/image/crypto/tf1.svg';
import FEATURE2 from '../../assets/image/crypto/tf2.svg';
import FEATURE3 from '../../assets/image/crypto/tf3.svg';
import FEATURE4 from '../../assets/image/crypto/tf4.svg';
import PROOF1 from '../../assets/image/crypto/rec.svg';
import PROOF2 from '../../assets/image/crypto/rec.svg';
import PROOF3 from '../../assets/image/crypto/rec.svg';
import PROOF4 from '../../assets/image/crypto/rec.svg';
import PROOF5 from '../../assets/image/crypto/rec.svg';
import PROOF6 from '../../assets/image/crypto/rec.svg';
import JACKPOTIMG from '../../assets/image/crypto/jackpot.svg';
import BETA1 from '../../assets/image/crypto/beta-1.svg';
import BETA2 from '../../assets/image/crypto/beta-2.svg';
import BETA3 from '../../assets/image/crypto/beta-3.svg';

import BETA4 from '../../assets/image/crypto/beta-5.svg';
import BETA5 from '../../assets/image/crypto/beta-6.svg';
import BETA6 from '../../assets/image/crypto/beta-8.svg';

export const MENU_ITEMS = [
  {
    label: 'HOME',
    path: '#banner_section',
    offset: '0',
  },
  {
    label: 'OUR SERVICES',
    path: '#trusted',
    offset: '-10',
  },
  {
    label: 'ABOUT US',
    path: '#betasection',
    offset: '110',
  },
  {
    label: 'CAREERS',
    path: '#scalable',
    offset: '-10',
  }  
];

export const TESTIMONIALS = [
  {
    review:
      '',
    name: 'API | Backend | Server solutions',
    designation: 'Ruby on rails, PHP, Python, .NET, Java and others',
    avatar: `${AuthorOne}`,
  },
  {
    review:
      '',
    name: 'Web | Hybrid | PWA',
    designation: 'Reactjs, React Native, Flutter and others',
    avatar: `${AuthorOned}`,
  },
  {
    review:
      '',
    name: 'Android Native Apps.',
    designation: 'Java & Kotlin',
    avatar: `${AuthorOnee}`,
  },
  {
    review:
      '',
    name: 'iOS Native apps.',
    designation: 'Objective-C & Swift',
    avatar: `${AuthorOnef}`,
  },
  {
    review:
      '',
    name: 'Enterprise Solutions',
    designation: 'ERP | CRM | Cloud Solutions ',
    avatar: `${AuthorOnec}`,
  },
  {
    review:
      '',
    name: 'Enterprise languages',
    designation: 'Cobol, Fortran, Powerbuilder, Magik and others.',
    avatar: `${AuthorOneb}`,
  },
  {
    review:
      '',
    name: 'Crypto & Blockchain',
    designation: 'Smart contracts, Coins, Wallets & Blockchain.',
    avatar: `${AuthorOneg}`,
  },
];
export const TRANSACTIONS_FEATURE = [
  {
    image: FEATURE1,
    title: 'Create Payment Address',
    des: 'Provide your payout wallet address and callback URL to PayBear API.',
  },
  {
    image: FEATURE2,
    title: 'Ask for Payment',
    des: 'Show your customer the wallet address as well as the payment amount.',
  },
  {
    image: FEATURE3,
    title: 'Get Paid',
    des: 'Payment is sent to the payout wallet immediately.',
  },
  {
    image: FEATURE4,
    title: 'Get Payment Notification.',
    des:
      'Callbacks are sent to the URL you specified. You can process customer order',
  },
];
export const PROOFS_FEATURE = [
  {
    image: PROOF1,
    title: 'API & Backend ',
    des: '',
  },
  {
    image: PROOF2,
    title: 'Web, Hybrid or PWA',
    des: '',
  },
  {
    image: PROOF3,
    title: 'Mobile Development',
    des: '',
  },
  {
    image: PROOF4,
    title: 'Enterprise solutions',    
    des: '',
  },
  {
    image: PROOF6,
    title: 'Crypto & Blockchain',
    des: '',
  },
  {
    image: PROOF5,
    title: 'Business Intelligence',
    des: '',
  }  
];
export const SCALABLE_FEATURE = [
  {
    image: JACKPOTIMG,
    title: 'Daily Jackpot',
    des: '35000 CLV',
  },
  {
    image: JACKPOTIMG,
    title: 'Weekly Jackpot',
    des: '250000 CLV',
  },
  {
    image: JACKPOTIMG,
    title: 'Monthly Jackpot',
    des: '4999697 CLV',
  },
  {
    image: JACKPOTIMG,
    title: 'Yearly Jackpot',
    des: '300245785000 CLV',
  },
];

export const BETA_FEATURE = [
  {
    image: BETA4,
    title: 'San Salvador, El Salvador ',
    des: 'Our home base.',
  },
  {
    image: BETA5,
    title: 'English, Spanish, Code and Emojis',
    des: 'We are fluent in',
  },
  {
    image: BETA6,
    title: 'Passion',
    des: 'We love to write code and solve problems.',
  },
];

export const menuWidget = [
  {
    id: 1,
    title: 'About us',
    menuItems: [
      {
        id: 1,
        url: '#',
        text: 'About us',
      },
      {
        id: 2,
        url: '#',
        text: 'Get a quote',
      },
      {
        id: 3,
        url: '#',
        text: 'Contact us',
      },
      // {
      //   id: 4,
      //   url: '#',
      //   text: 'Copyright',
      // },
      // {
      //   id: 5,
      //   url: '#',
      //   text: 'Popular Campaign',
      // },
    ],
  },
  {
    id: 2,
    title: 'Our Services',
    menuItems: [
      {
        id: 1,
        url: '#',
        text: 'API & Backend',
      },
      {
        id: 2,
        url: '#',
        text: 'Web development',
      },
      {
        id: 3,
        url: '#',
        text: 'Apps development',
      },
      {
        id: 4,
        url: '#',
        text: 'Enterprise solutions',
      },
      {
        id: 5,
        url: '#',
        text: 'Featured Solutions',
      },
    ],
  },
  {
    id: 3,
    title: "Let's talk about your project",
    menuItems: [
      {
        id: 1,  
        url: 'mailto:info@codity.tech',      
        text: 'info@codity.tech',
      },
      // {
      //   id: 2,
      //   url: '#',
      //   text: 'Social media directories',
      // },
      // {
      //   id: 3,
      //   url: '#',
      //   text: 'Images & B-roll',
      // },
      // {
      //   id: 4,
      //   url: '#',
      //   text: 'Permissions',
      // },
      // {
      //   id: 5,
      //   url: '#',
      //   text: 'Speaker requests',
      // },
    ],
  },
];
export const Language_NAMES = [
  {
    label: 'English',
    value: 'eng',
  },
  {
    label: 'Chinese',
    value: 'ch',
  },
  {
    label: 'Spanish',
    value: 'sp',
  },
];
